class App {
    constructor() {
        if (!window.pms) window.pms = {};
        if (!pms.config) pms.config = {};
        this.initGlobalErrorHandler();
        this.initSW();
        document.addEventListener('DOMContentLoaded', this.init);
    }

    async init() {
        let traceRequest = await fetch('/system/extensions/webApp/trace');
        let traceResponse = await traceRequest.json();
        if (traceResponse.data && traceResponse.data.User) {
            let userData = traceResponse.data.User;
            userData.id = parseInt(userData.id);
            pms.user = userData;
            if (window.yaCounter47500810) {
                window.yaCounter47500810.setUserID(pms.user.id);
                window.yaCounter47500810.userParams({
                    UserID: pms.user.id,
                    email: pms.user.email,
                    name: pms.user.name,
                    wishlistCount: pms.user.wishlistCount
                });
            }
        }
    }

    initSW() {
        if ('serviceWorker' in navigator) {
            if (navigator.serviceWorker.controller) console.log('[PWA Builder] active service worker found, no need to register');
            else navigator.serviceWorker.register('/pwabuilder-sw.js', {
                scope: '/'
            }).then(function (reg) {
                console.log('Service worker has been registered for scope:' + reg.scope);
            });
        }
    }

    initGlobalErrorHandler() {
        window.onerror = function (msg, url, line, col, error) {
            // Note that col & error are new to the HTML 5 spec and may not be
            // supported in every browser.  It worked for me in Chrome.
            let extra = !col ? '' : `
column: ` + col;
            extra += !error ? '' : `
error: ` + error;

            // You can view the information in an alert to see things working like this:
            // alert("Error: " + msg + "\nurl: " + url + "\nline: " + line + extra);

            // TODO: Report this error via ajax so you can keep track
            //       of what pages have JS issues

            let endpoint = '/system/extensions/errorCatcher/';
            let message = `Error: ${msg}
url: ${url}
line: ` + line + extra;

            if (navigator.sendBeacon) navigator.sendBeacon(endpoint, message);
            else fetch(endpoint, {
                method: 'POST',
                credentials: 'same-origin',
                body: message
            });

            if (window.yaCounter47500810) window.yaCounter47500810.params({error: message});

            return false;

            // var suppressErrorAlert = true;
            // If you return true, then error alerts (like in older versions of
            // Internet Explorer) will be suppressed.
            // return suppressErrorAlert;
        };
    }
}

new App();